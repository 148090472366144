import React, { useState } from "react"

import { useTimer } from 'react-timer-hook';
import { Button } from "nes-react"

import hypno from './birds/hypno_bird.png'
import butcher from './birds/butcher_bird.png'
import fire from './birds/fire_bird.png'
import gangsta from './birds/gangsta_bird.png'
import gravedigger from './birds/gravedigger_bird.png'
import invisible from './birds/invisible_bird.png'
import money from './birds/money_bird.png'
import nudie from './birds/nudie_bird.png'
import psycho from './birds/psycho_bird.png'
import rave from './birds/rave_bird.png'
import sixpack from './birds/sixpack_bird.png'
import stripper from './birds/stripper_bird.png'
import swede from './birds/swede_bird.png'
import mini from './birds/mini_bird.png'
import santa from './birds/santa_bird.png'
import laser from './birds/laser_bird.png'
import moneylaundry from './birds/moneylaundry_bird.png'
import pimp from './birds/pimp_bird.png'
import hacker from './birds/hacker_bird.png'
import dokwon from './birds/dokwon_bird.png'
import explosive from './birds/explosive_bird.png'
import bigballs from './birds/big_balls_bird.png'
import meth from './birds/meth_bird.png'
import robber from './birds/robber_bird.png'
import rick from './birds/rick_bird.png'
import clown from './birds/clown_bird.png'
import trump from './birds/trump_bird.png'
import drunk from './birds/drunk_bird.png'
import britney from './birds/britney_bird.png'
import playboy from './birds/playboy_bird.png'
import squidi from './birds/squidi_bird.png'
import bridezilla from './birds/bridezilla_bird.png'
import ninja from './birds/ninja_bird.png'
import capitol from './birds/capitol_bird.png'
import kreuger from './birds/kreuger_bird.png'
import hefner from './birds/hefner_bird.png'
import jason from './birds/jason_bird.png'
import evil from './birds/evil_bird.png'
import daddy from './birds/daddy_bird.png'
import control from './birds/control_bird.png'

import logo from './logo.svg'
import './App.css'

const birds = [
  {
    name: 'hypno bird',
    color: '#29C26D',
    img: hypno,
    whiteFont: false,
    txt: 'You will not remember anything!'
  },
  {
    name: 'butcher bird',
    color: '#CD4162',
    img: butcher,
    whiteFont: true,
    txt: 'Chest hair, pipe smoker, no limits. Fetisch for fingers…'
  },
  {
    name: 'fire bird',
    color: '#A38AFF',
    img: fire,
    whiteFont: false,
    txt: 'Remember Prodigy - Firstarter? It’s him!'
  },
  {
    name: 'gangsta bird',
    color: '#5F3CDF',
    img: gangsta,
    whiteFont: true,
    txt: 'O.G To cool!'
  },
  {
    name: 'gravedigger bird',
    color: '#7EDD79',
    img: gravedigger,
    whiteFont: false,
    txt: 'Puts the nail in the coffin! Unfriend!'
  },
  {
    name: 'invisible bird',
    color: '#41CD9D',
    img: invisible,
    whiteFont: false,
    txt: 'No comments!'
  },
  {
    name: 'no-money bird',
    color: '#FD80F9',
    img: money,
    whiteFont: false,
    txt: 'Lives decentralized - darknet, crypto… you name it!'
  },
  {
    name: 'nudie bird',
    color: '#FF65C3',
    img: nudie,
    whiteFont: false,
    txt: 'Do not have time to comb, other things to think about (;'
  },
  {
    name: 'psycho bird',
    color: '#5661EE',
    img: psycho,
    whiteFont: true,
    txt: 'Ice cold, do not even feel that a sword has passed through the head'
  },
  {
    name: 'rave bird',
    color: '#89BDBE',
    img: rave,
    whiteFont: false,
    txt: 'Let’s get this party started!'
  },
  {
    name: 'sixpack bird',
    color: '#54D3BA',
    img: sixpack,
    whiteFont: false,
    txt: 'Anything but the Big Pack! Sleazy dude!'
  },
  {
    name: 'stripper bird',
    color: '#A0E8D3',
    img: stripper,
    whiteFont: false,
    txt: 'Show me the money MF!'
  },
  {
    name: 'swede bird',
    color: '#A56A62',
    img: swede,
    whiteFont: true,
    txt: 'King of meatballs and balls!'
  },
  {
    name: 'mini but cocky bird',
    color: '#EBE546',
    img: mini,
    whiteFont: false,
    txt: 'Cockiest in the gang, of course! Small bird, high shoes!'
  },
  {
    name: 'drunken santa bird',
    color: '#D1AB49',
    img: santa,
    whiteFont: false,
    txt: 'Don’t eat the yellow snow!'
  },
  {
    name: 'laser bird',
    color: '#583BCC',
    img: laser,
    whiteFont: true,
    txt: 'Luna to the Moon!'
  },
  {
    name: 'money laundry bird',
    color: '#C6E84A',
    img: moneylaundry,
    whiteFont: false,
    txt: 'F**k the government!'
  },
  {
    name: 'pimp bird',
    color: '#6EFF6B',
    img: pimp,
    whiteFont: false,
    txt: 'Pimp N Limp'
  },
  {
    name: 'hacker bird',
    color: '#3B8A3A',
    img: hacker,
    whiteFont: true,
    txt: 'Your backdoor is open'
  },
  {
    name: 'dokwon sue the SEC bird',
    color: '#ABADB8',
    img: dokwon,
    whiteFont: false,
    txt: 'Say hello to my little friend…'
  },
  {
    name: 'explosive bird',
    color: '#CF8D4C',
    img: explosive,
    whiteFont: false,
    txt: 'Don\'t trigger him'
  },
  {
    name: 'big balls bird',
    color: '#2C4BE8',
    img: bigballs,
    whiteFont: true,
    txt: 'Probably the biggest balls in town'
  },
  {
    name: 'meth lady bird',
    color: '#31BABD',
    img: meth,
    whiteFont: false,
    txt: 'Not the ordinary lady, relative to crazy cat lady'
  },
  {
    name: 'robber bird',
    color: '#FC957E',
    img: robber,
    whiteFont: false,
    txt: 'Fears no evil'
  },
  {
    name: 'rick astley bird',
    color: '#BAC1F5',
    img: rick,
    whiteFont: false,
    txt: 'Gonna give you up!'
  },
  {
    name: 'killerclown bird',
    color: '#F4FF57',
    img: clown,
    whiteFont: false,
    txt: 'Out for ya!'
  },
  {
    name: 'trump bird',
    color: '#948159',
    img: trump,
    whiteFont: false,
    txt: 'You\'re fired!'
  },
  {
    name: 'drunk bird',
    color: '#106EE0',
    img: drunk,
    whiteFont: true,
    txt: 'Vomit!'
  },
  {
    name: 'britney bird',
    color: '#FFFF75',
    img: britney,
    whiteFont: false,
    txt: 'It\'s Britney. Bitch!'
  },
  {
    name: 'playboy bird',
    color: '#FC9FEA',
    img: playboy,
    whiteFont: false,
    txt: 'Minimal tissues and issues'
  },
  {
    name: 'squidi bird',
    color: '#4A9BFF',
    img: squidi,
    whiteFont: false,
    txt: 'Start stop!'
  },
  {
    name: 'bridezilla bird',
    color: '#F587EC',
    img: bridezilla,
    whiteFont: false,
    txt: 'Always sleep with an open eye...'
  },
  {
    name: 'ninja bird',
    color: '#6BFF7F',
    img: ninja,
    whiteFont: false,
    txt: 'Shop shop'
  },
  {
    name: 'capitol bird',
    color: '#E5FF99',
    img: capitol,
    whiteFont: false,
    txt: 'Lets riot! Seams clever..'
  },
  {
    name: 'kreuger bird',
    color: '#2FC256',
    img: kreuger,
    whiteFont: false,
    txt: 'You\'re worst nightmare'
  },
  {
    name: 'hugh hefner bird',
    color: '#F4FF61',
    img: hefner,
    whiteFont: false,
    txt: 'Not nanny material'
  },
  {
    name: 'jason bird',
    color: '#3BEFFF',
    img: jason,
    whiteFont: false,
    txt: 'Schyyyy fall to sleep'
  },
  {
    name: 'evil bird',
    color: '#8A8787',
    img: evil,
    whiteFont: false,
    txt: 'Fear no evil or not mohahaha'
  },
  {
    name: 'suggar daddy bird',
    color: '#2FC256',
    img: daddy,
    whiteFont: false,
    txt: 'Who\'s your daddy!'
  },
  {
    name: 'Out of control bird',
    color: '#0892C4',
    img: control,
    whiteFont: true,
    txt: 'Not really in control!'
  },
]

function App() {
  const [ button, setButton ] = useState(true);
  const { 
    // eslint-disable-next-line
    seconds, minutes, hours, days
  } = useTimer({ expiryTimestamp: 1637280000000, onExpire: () => setButton(false) });

  const [ selectedBird, setBird ] = useState(Math.floor(Math.random() * birds.length))
  document.body.style = `background-color: ${birds[selectedBird].color} !important; color: ${birds[selectedBird].whiteFont ? 'white' : 'black'};`

  if(window.innerWidth < 450){
    document.querySelector('body').onclick = () => setBird(selectedBird + 1 >= birds.length ? 0 : selectedBird + 1)
  }

  return (
    <>
      <div className='left_arrow' onClick={() => setBird(selectedBird - 1 > 0 ? selectedBird - 1 : birds.length - 1)}>&#62;</div>
      <div className='right_arrow' onClick={() => setBird(selectedBird + 1 >= birds.length ? 0 : selectedBird + 1)}>&#62;</div>
      <div className="body__container">
        <img src={logo} alt='Scandalous birds' className='logo' />
        <p className='text' >NOT ALL BIRDS WANT TO BE SONGBIRDS</p>
        <div className='button'><Button primary disabled={button}>GET YOUR BIRD</Button></div>
        <div className='countdown' style={{ display: button ? 'block' : 'none' }}>COMING SOON...{/*{days}d {hours}h {minutes}m {seconds}s*/}</div>
        <img src={birds[selectedBird].img} alt='bird' className='bird' />
        <p className='bird__name'>{birds[selectedBird].name.toUpperCase()}</p>
        <p className='bird__txt'>{birds[selectedBird].txt.toUpperCase()}</p>
      </div>
    </>
  )
}

export default App
